import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { get } from "../../../utils/apiMethods";
import { GET_CONTACTUS_URL } from "../../../utils/apiConstants";
import DataNotFound from "../../../pages/NotFound/DataNotFound"

export default function ContactUsAdmin() {
  const [data, setData] = useState([]);

  useEffect(() => {
    callAPI();
  }, []);

  const callAPI = async () => {
    const data = await get({ url: GET_CONTACTUS_URL });
    if (data?.data.length) {
      setData(data.data);
    }
  };
  const tableComponent = () => {
    return (
      <div className="m-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone number</th>
              <th>Email-id</th>
              <th>Message</th>
              <th>Interested In</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
                data.map((item, index) => {
                  return (
                    <tr>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.email}</td>
                    <td>{item.message}</td>
                    <td>
                      {item.checkbox.map((item) => {
                        return (
                          <>
                            {item.name}, 
                          </>
                        )
                      })}
                    </td>
                  </tr>
                  )
                })
           
            ) : null}
          </tbody>
        </Table>
      </div>
    );
  };
  return <>{data.length ? tableComponent() : <DataNotFound />}</>;
}
