import React, { Component, useEffect, useState } from "react";
import "./Header.css";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { AiFillMail } from "react-icons/ai";
import Navbar from "../Navbar/Navbar";
import { faceBook, twitter } from "../../utils/StringConstants";

export default function Header() {
  const [isTrue, setIsTrue] = useState(false);
  
  useEffect(() => {
    setIsTrue(!isTrue);
  }, [window.location.pathname]);

  return (
    <div
      className={
        window.location.pathname === "/"
          ? "home-header"
          : window.location.pathname === "/aboutus"
          ? "about-pages-header"
          : window.location.pathname.includes("/gallery/wedding")
          ? "services-pages-header"
          : window.location.pathname.includes("/gallery/maternity")
          ? "maternity-pages-header"
          : window.location.pathname.includes("/gallery/kids")
          ? "kids-pages-header"
          : window.location.pathname === "/contact"
          ? "contact-pages-header"
          : window.location.pathname === "/faq"
          ? "faq-pages-header"
          // : window.location.pathname.includes("/admin")
          // ? "admin-pages-header"
          : ""
      }
    >
      <Navbar />
    </div>
  );
}
