import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  ADD_BANNER_URL,
  ADD_VIDEO_URL,
  DELETE_VIDEO_URL,
  GET_BANNER_URL,
  GET_VIDEO_URL,
  MAIN_URL,
} from "../../../utils/apiConstants";
import { get, post } from "../../../utils/apiMethods";
import DataNotFound from "../../NotFound/DataNotFound";

export default function VideoAdmin() {
  const [isModelOn, setIsModelOn] = useState(false);
  const [title, setTitle] = useState("");
  const [youTubeUrl, setYouTubeUrl] = useState("");
  const [thumbnailimg, setthumbnailImg] = useState();
  const [bannerData, setBannerData] = useState([]);

  const callGetApi = async () => {
    const result = await get({ url: GET_VIDEO_URL });
    if (result?.data?.length) {
      setBannerData(result.data);
    }
  };

  useEffect(() => {
    callGetApi();
  }, []);

  const handleModel = () => {
    setIsModelOn(!isModelOn);
  };

  const thumbnamilImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    // Now you can proceed to upload the selected image.
    setthumbnailImg(selectedImage);
  };

  const handleSubmit = async () => {
    if (title === "") {
      alert("enter title");
    } else if (youTubeUrl === "") {
      alert("enter youurl");
    } else {
      const obj = {
        title,
        videoUrl: youTubeUrl,
      };
      const response = await post({ url: ADD_VIDEO_URL, obj: obj });
      if (response.status) {
        callGetApi();
        handleModel();
      }
    }
  };

  const handleDelete = async (id) => {
    const result = await get({ url: `${DELETE_VIDEO_URL}/${id}` });
    if (result.status) {
      window.location.reload();
    }
  };

  const tableComponent = () => {
    return (
      <>
        <div className="d-flex justify-content-end">
          <Button
            className="m-4 "
            variant="primary"
            onClick={() => handleModel()}
          >
            Add Video
          </Button>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {bannerData.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>
                    <Image src={item.imageUrl} thumbnail />
                  </td>
                  <td>
                    <Row>
                      {/* <Col md="2">
                      <Button>Edit</Button>
                    </Col> */}
                      <Col md="2">
                        <Button onClick={() => handleDelete(item._id)}>
                          Delete
                        </Button>
                      </Col>
                      {/* <Col>
                      <Button>Edit</Button>
                    </Col> */}
                    </Row>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const addModelGallery = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModelOn}
      >
        <Modal.Body>
          <div className="container mt-5">
            <Row>
              <Col xl="6" md="6">
                <Form.Control
                  placeholder="Title"
                  aria-label="Title"
                  onChange={(e) => setTitle(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Row className="py-2">
              <Col xl="6" md="6">
                <Form.Control
                  placeholder="videoUrl (ex: https://www.youtube.com/watch?v=VCQKt2j_m-8)"
                  aria-label="Title"
                  onChange={(e) => setYouTubeUrl(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleModel()}>
            Close
          </Button>
          <Button variant="success" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {bannerData.length ? (
        tableComponent()
      ) : (
        <>
          <div className="d-flex justify-content-end">
            <Button
              className="m-4 "
              variant="primary"
              onClick={() => handleModel()}
            >
              Add Video
            </Button>
          </div>
          <DataNotFound />
        </>
      )}
      {addModelGallery()}
    </>
  );
}
