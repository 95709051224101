export const MAIN_URL = "https://api.lightflixproductions.com";
export const BASE_URL = `${MAIN_URL}v1/webapp`;
export const IMAGE_BASE_URL = "https://bjpapi.anandmamani.in";
export const IMAGE_BASE1_URL = "https://bjpapi.anandmamani.in/";
export const GET_PROFILE_DATA = `${BASE_URL}/profile`;
export const GET_GALLERY_DATA = `${BASE_URL}/cmsData/get`;
export const GET_BANNER_DATA = `${BASE_URL}/banner`;
export const CONTACT_US = `${BASE_URL}/contactus/add`;

export const GET_GALLERY_URL = `${MAIN_URL}/get/gallery`;
export const DELETE_GALLERY_URL = `${MAIN_URL}/delete/gallery`;
export const ADD_GALLERY_URL = `${MAIN_URL}/add/gallery`
export const ADD_CONTACTUS_URL = `${MAIN_URL}/add/contactus`
export const GET_CONTACTUS_URL = `${MAIN_URL}/get/contactus`
export const ADD_BANNER_URL = `${MAIN_URL}/add/banner`
export const GET_BANNER_URL = `${MAIN_URL}/get/banner`
export const DELETE_BANNER_URL = `${MAIN_URL}/delete/banner`
export const GET_VIDEO_URL = `${MAIN_URL}/get/video`
export const ADD_VIDEO_URL = `${MAIN_URL}/add/video`
export const DELETE_VIDEO_URL = `${MAIN_URL}/delete/video`

export const ADD_CUSTOMER_URL = `${MAIN_URL}/add/happyCustomer`
export const GET_CUSTOMER_URL = `${MAIN_URL}/get/happyCustomer`
export const DELETE_CUSTOMER_URL = `${MAIN_URL}/delete/happyCustomer`