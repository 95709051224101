import React, { Component } from "react";
import FbIframe from "../FbIframe/FbIframe";
import "./Footer.css";
import logo from "../../assets/images/logo.png";
import logoH from "../../assets/images/logo.png";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaFacebookSquare,FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { faceBook, twitter } from "../../utils/StringConstants";
import Twitterframe from "../Twitter/TwitterFrame";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <div class="card" style={{ marginTop: "8px" }}>
          <div class="row mb-4 ">
            <div class="col-md-6 col-sm-11 col-xs-11">
              <div>
                <div>
                  <img
                    className="d-block logoImg"
                    src={logoH}
                    alt="Logo"
                  />
                </div>
              </div>
              </div>
              <div class="col-md-6 col-sm-11 col-xs-11">
                <p class="card-text">
                All Images and Videos Are Copyrighted. Tags: Wedding Films, Wedding Cinema, Fine-Art Cinematography, Cinematic Films, Wedding videos, Wedding Cinematographers in Mumbai, Delhi, Bangalore, Goa, London, Wedding Photography, Destination Wedding Photographer, Best Wedding Photographer
                </p>
                <div class="social mt-2 mb-3">
                  <div class="social-icons">
                  <a className="links" href={"https://www.youtube.com/@lightflixproductions/videos"} target="_blank">
                    <FaYoutubeSquare className="footerIcon"  />
                    </a>
                    <a className="links" href={faceBook} target="_blank">
                      <FaFacebookSquare className="footerIcon" />
                    </a>
                    <a className="links" href={twitter} target="_blank">
                      <FaInstagramSquare className="footerIcon" />
                    </a>
                    {/* <FaLinkedin className="footerIcon" />{" "} */}
                  </div>
                </div>
              </div>

          </div>
          <div class="divider mb-4"> </div>
          <div class="row" style={{ fontSize: "10px" }}>
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="pull-left">
                <p className="copyPara">
                  Copyright©2024 lightflix-productions, All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    );
  }
}
