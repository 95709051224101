import React, { Component } from "react";
import "./Aboutinfo.css";
import photo from "../../assets/images/Untitled-1.tif";
import { withRouter } from "react-router-dom";
import { GET_PROFILE_DATA, IMAGE_BASE_URL } from "../../utils/apiConstants";
import { get } from "../../utils/apiMethods";
import { Card, CardHeader, CardBody } from "react-bootstrap";
import founderImage from '../../assets/images/founder.JPEG';
// import CardHeader from "react-bootstrap/esm/CardHeader";

class Aboutinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generalData: "",
      name: "",
      Position: "",
    };
  }

  async componentDidMount() {
    const data = await get({ url: GET_PROFILE_DATA });
    if (data) {
      this.setState({
        name: data.name,
        generalData: data.General,
        Position: data.Position,
      });
    }
  }
  render() {
    const { history } = this.props;
    const { name, Position } = this.state;
    return (
      <div class="container about-us mt-5">
        <div class="col-xs-12 col-12 col-lg-6 text-center text-sm-left order-2 order-lg-2">
          {/* <Card> */}
            <h4 class="special-heading text-sm-left ">
              <span class="above">Welcome to LIGHTFLIX PRODUCTIONS</span>
            </h4>
            <h3 class="special-heading text-sm-left ">
              <span class="underline">At LightFlix Productions</span>
            </h3>
            {/* <Card> */}
            <p className="abtPara">
              <em style={{fontFamily:'futura'}}>
              We are a team of passionate photographers dedicated to pushing the
              boundaries of visual storytelling through our lens, aiming to
              capture moments that invite viewers to imagine beyond the
              ordinary. Our journey began with a love for photography, and over
              time, we've turned moments into art." Vision: We believe that
              photography is more than just a snapshot; it's a portal to worlds
              unseen and emotions unfelt. Through our work, we strive to
              transport our audience to new dimensions, sparking curiosity and
              wonder. Approach: Our photography is a blend of art and technique.
              We combine innovative techniques, cutting-edge equipment, and a
              keen eye for detail to create images that defy conventional
              boundaries. We embrace experimentation, always seeking fresh ways
              to convey our artistic vision. What sets us apart is our
              unwavering dedication to personalised service. We firmly believe
              that every client is unique , and whether it's capturing the
              timeless essence of weddings, the beauty of maternity, the
              innocence of kids, or the warmth of family portraits, we approach
              each project with a tailor-made touch to bring your unique vision
              to life. We have a team of talented photographers, each with their
              own distinct style and expertise, all united by a passion for
              creating images that resonate. We're not just photographers; we're
              storytellers, and we can't wait to tell your story.
              </em>
            </p>
            {/* </Card> */}
            <div>
              <h4 className="special-heading text-sm-left">FOUNDER</h4>
              <img className="imageFounder" src={founderImage} />
              <h1 className="founder">{`Sharath Chandrappa`}</h1>
              <span>FOUNDER, MANAGING DIRECTOR, LEAD PHOTOGRAPHER</span>
              {/* <Card> */}
              <p>
              <em style={{fontFamily:'futura'}}>
             
                A software engineer who chose to forego an onsite opportunity to
                embark on a journey into the world of photography. In late
                2000’s his passion towards photography made him explore
                then-emerging realm of candid and creative photography when it
                was still a fresh and innovative approach.Sharath consistently
                breaks creative boundaries, a passion he extends to his candid
                photography, crafting unique visual narratives. His candid
                photography-focused Facebook page gathered a substantial
                following and sparked a wave on platform.His journey from a
                hobbyist to a passionate professional photographer is not only
                inspiring but has also empowered numerous aspiring photographers
                he's mentored along the way."
                </em>
              </p>
              {/* </Card> */}
            </div>
          {/* </Card> */}
        </div>
      </div>
    );
  }
}
export default withRouter(Aboutinfo);
