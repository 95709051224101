import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./GalleryNew.css";
import { get } from "../../utils/apiMethods";
import { GET_GALLERY_URL, MAIN_URL } from "../../utils/apiConstants";

class GalleryNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryData: []
        }
    }

    callGetApi = async () => {
      const result = await get({ url: GET_GALLERY_URL });
      if (result?.data?.length) {
        const type = this.props.match.params.type;
        console.log("type", type);
        const filterData = result.data.filter((item) => item.type === type);
        this.setState({galleryData: filterData});
      }
    };

    componentDidMount() {
      this.callGetApi();
      
    }

    onHandleSubmit = (item) => {
        const {history, match: {params: {type}}}=this.props;
        history.push(`/gallery/${type}/${item.title}/${item._id}`);
    }

  render() {
    let mobilecss=window.innerWidth<670 ?"row":"row m-5";
    const {match: {params: {type}}} = this.props;
    // const type = this.props.match.params.type;
    const { galleryData } = this.state; 
    return (
      <div class="p-2">
        <h2>{`${type.toUpperCase()} - Gallery`}</h2>
        <div className="container w-75">
          <div className={mobilecss}>
            {galleryData.map((item, index) => (
              <div key={index} className="col-md-4 spacing"  onClick={() => this.onHandleSubmit(item)}>
                <img
                  src={`${MAIN_URL}/${item?.thumbnailImgUrl?.path}`}
                  alt={`Image ${index + 1}`}
                  className="img-fluid"
                />
                <h5>{item.title}</h5>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(GalleryNew);
