import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Navbar.css";
import logoH from "../../assets/images/logo2.png";
import logoHA from "../../assets/images/logo.png";
class Navbar extends Component {
  render() {
    const { history } = this.props;
    const userType = localStorage.getItem("userType");
    const mainRoutes = userType === "admin" ? adminMenu : routes;
    return (
      <nav class="navbar navbar-dark navbar-expand-sm navbarMenus">
        <div class="container mobdiv">
          <p class="navbar-brand navLogo">
            <img
              src={window.location.pathname.includes("/admin") ? logoHA : logoH}
              className="logoHeader"
              onClick={() => window.location.assign("/")}
            />
          </p>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div id="navbarCollapse" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto marLeft">
              {mainRoutes.map((item, index) => {
                const isActive =
                  window.location.pathname === item.path ? "home" : "";
                if (item.title === "Gallery") {
                  return (
                    <li class="nav-item dropdown">
                      <a
                        class={window.location.pathname === "/"?`nav-link navClass `:` nav-dark nav-link  active ${isActive}`}
                        // class="nav-link dropdown-toggle navClass"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        
                      >
                        {item.title.toLocaleUpperCase()}
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {photoTypes.map((item, i) => {
                          return (
                            <li>
                              <p
                                class="dropdown-item"
                                onClick={() =>
                                  window.location.assign(item.path)
                                }
                              >
                                {item.title.toLocaleUpperCase()}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li
                      class="nav-item"
                      onClick={() =>  window.location.assign(item.path)}
                    >
                      <p
                        class={window.location.pathname === "/"?`nav-link navClass active ${isActive}`:` nav-dark nav-link  active ${isActive}`}
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                        style={window.location.pathname.includes("/admin")?{color:"black"}:{}}
                      >
                        {item.title.toLocaleUpperCase()}
                      </p>
                    </li>
                  );
                }
              })}
              {userType === "admin" ? (
                <div
                  onClick={() => {
                    localStorage.clear();
                    window.location.assign("/");
                  }}
                >
                  Logout
                </div>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
export default withRouter(Navbar);
const routes = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About us",
    path: "/aboutus",
  },
  {
    title: "Gallery",
    path: "/gallery",
  },
  {
    title: "Contact",
    path: "/contact",
  },
  {
    title: "FAQ",
    path: "/faq",
  },

  // {
  //   title: "Career",
  //   path: "/career",
  // },
];
const photoTypes = [
  {
    title: "Wedding",
    path: "/gallery/wedding",
  },
  {
    title: "Maternity",
    path: "/gallery/maternity",
  },
  {
    title: "Kids",
    path: "/gallery/kids",
  },
];

const adminMenu = [
  {
    title: "Gallery",
    path: "/admin/gallery",
  },
  {
    title: "Banner",
    path: "/admin/banner",
  },
  {
    title: "Contact us",
    path: "/admin/contactus",
  },
  {
    title: "Video Gallery",
    path: "/admin/video",
  },
  {
    title: "Happy Customer",
    path: "/admin/happyCustomer",
  },
];
