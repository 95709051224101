import React, { Component } from "react";
import "./ContactForm.css";
import { FiPhoneCall } from "react-icons/fi";
import { AiFillMail } from "react-icons/ai";
import { BiMap } from "react-icons/bi";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { faceBook, twitter } from "../../utils/StringConstants";
import { post } from "../../utils/apiMethods";
import { ADD_CONTACTUS_URL, CONTACT_US } from "../../utils/apiConstants";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import emailjs from '@emailjs/browser';

const emailTest =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const regexOnlyNumber = /^[0-9]+$/;
export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameErr: "",
      email: "",
      emailErr: "",
      phoneNumber: "",
      numberErr: "",
      message: "",
      messageErr: "",
      eventLocation: "",
      howDidYouHear:"",
      options: [
        'FACEBOOK', 'INSTAGRAM', 'WHATSAPP',"OTHERS"
      ],
      checkbox: [
        {
          name: "WEDDING",
          checked: false,
        },
        {
          name: "MATERNITY",
          checked: false,
        },
        {
          name: "KIDS",
          checked: false,
        },
        {
          name: "OTHERS",
          checked: false,
        },
      ],
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };
  handleValidation = (e) => {
    e.preventDefault();

    const { name, email, phoneNumber, message } = this.state;

    if (name === "") {
      this.setState({ nameErr: "Please enter your name " });
      return false;
    } else {
      this.setState({ nameErr: "" });
    }

    if (email === "") {
      this.setState({ emailErr: "Please enter your email " });
    } else if (!emailTest.test(email)) {
      this.setState({ emailErr: "Please enter valid email " });
      return false;
    } else {
      this.setState({ emailErr: "" });
    }

    if (phoneNumber === "") {
      this.setState({ numberErr: "Please enter your phone number " });
    } else if (!regexOnlyNumber.test(phoneNumber)) {
      this.setState({ numberErr: "Please enter valid number " });
    } else if (phoneNumber.length < 10) {
      this.setState({ numberErr: "Phone number should be 10 digits " });
      return false;
    } else {
      this.setState({ numberErr: "" });
    }

    if (message === "") {
      this.setState({ messageErr: "Please enter message" });
      return false;
    } 
    else {
      this.setState({ messageErr: " " });
      this.onSubmit();
    }
  };
  onSubmit = async () => {
    const { name, email, phoneNumber, message, checkbox, eventLocation, howDidYouHear } = this.state;
    const checkedItem = checkbox.filter((item) => item.checked === true);
    const obj = {
      name: name,
      email: email,
      phone_number: phoneNumber,
      message: message,
      checkbox: checkedItem,
      eventLocation,
      howDidYouHear
    };
    const response = await post({ url: ADD_CONTACTUS_URL, obj: obj });
    if (response.status) {
      // alert(response.message);
      var templateParams = {
        email: email,
        name: name,
        message: message,
        eventlocation: eventLocation,
        subject: `Enquire from ${name}`,
        phonenumber: phoneNumber,
        items: JSON.stringify(checkedItem)
      };

      const templateCode = "template_g880dyu";
      const serviceCode = "service_zgy1mcq";
      const publicCode = "6kO7QlO8TkoFk8LEx";

      emailjs.send(serviceCode, templateCode, templateParams, publicCode).then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          alert(
            "Thank you for submitting your form. We will get back to you shortly!"
          );
          window.location.reload();
        },
        function (err) {
          alert("Failed in sending email to admin")
        }
      );
    }
  };

  handleCheckbox = (index) => {
    const tempCheckBox = [...this.state.checkbox];
    tempCheckBox[index].checked = !tempCheckBox[index].checked;
    this.setState({ checkbox: tempCheckBox });
  };

  onSelectChange = (e) => {
    console.log("Coing", e);
    this.setState({howDidYouHear: e.value});
  }

  render() {
    const {
      nameErr,
      emailErr,
      numberErr,
      messageErr,
      name,
      email,
      phoneNumber,
      message,
      checkbox,
      eventLocation,
      howDidYouHear,
      options
    } = this.state;
    return (
      <div class="container conContainer">
        <div class="Contactform">
          <div class="contact-info">
            <h3 class="title">Let's get in touch</h3>
            <p class="text">
              {" "}
              To contact us . Please fill up the form with required details.
            </p>
            <div class="info">
              {/* <div class="social-information">
                {" "}
                <BiMap className="contactAddIcon" />
                <p>Sharath Chandrappa Surabhi Heights, flat 103, Second floor.  Owners court west, Kasavanahalli Bangalore  560035</p>
              </div> */}
              <div class="social-information">
                {" "}
                <AiFillMail className="contactAddIcon1" />
                <p>lightflixproductions@gmail.com</p>
              </div>
              <div class="social-information">
                {" "}
                <FiPhoneCall className="contactAddIcon1" />
                <p>+91 8618431989</p>
              </div>
              <div class="social-information">
                {" "}
                <FiPhoneCall className="contactAddIcon1" />
                <p>+91 9538011627</p>
              </div>
            </div>
            <div class="social-media">
              <p>Connect with us :</p>
              <div class="social-icons">
                {" "}
                <a href="https://www.youtube.com/@lightflixproductions/videos">
                  {" "}
                  <FaYoutubeSquare className="contactIcon" />{" "}
                </a>{" "}
                <a href={faceBook} target="_blank">
                  {" "}
                  <FaFacebookSquare className="contactIcon" />{" "}
                </a>{" "}
                <a href={twitter} target="_blank">
                  {" "}
                  <FaInstagramSquare className="contactIcon" />{" "}
                </a>{" "}
              </div>
            </div>
          </div>
          <div class="contact-info-form">
        
            <form
              action="#"
              onclick="return false;"
              autocomplete="off"
              className="mainForm"
            >
              <h3 class="title">Contact us</h3>
              <div class="social-input-containers">
                {" "}
                <input
                  type="text"
                  name="name"
                  class="inputBox"
                  placeholder="Name"
                  onChange={(e) => this.handleChange(e)}
                  value={name}
                />{" "}
                {nameErr ? <p className="error">{nameErr}</p> : ""}
              </div>
              <div class="social-input-containers">
                {" "}
                <input
                  type="email"
                  name="email"
                  class="inputBox"
                  placeholder="Email"
                  onChange={(e) => this.handleChange(e)}
                  value={email}
                />{" "}
                {emailErr ? <p className="error">{emailErr}</p> : ""}
              </div>
              <div class="social-input-containers">
                {" "}
                <input
                  type="tel"
                  name="phoneNumber"
                  class="inputBox"
                  placeholder="Phone"
                  maxLength={10}
                  onChange={(e) => this.handleChange(e)}
                  value={phoneNumber}
                />{" "}
                {numberErr ? <p className="error">{numberErr}</p> : ""}
              </div>
              <div class="social-input-containers textarea">
                {" "}
                <textarea
                  name="message"
                  class="inputBox"
                  placeholder="Event Details"
                  onChange={(e) => this.handleChange(e)}
                  value={message}
                ></textarea>{" "}
              </div>{" "}
              {messageErr ? <p className="error">{messageErr}</p> : ""}
              <div class="social-input-containers">
                  {" "}
                  <input
                    type="text"
                    name="eventLocation"
                    class="inputBox"
                    placeholder="Event Location"
                    onChange={(e) => this.handleChange(e)}
                    value={eventLocation}
                  />
                </div>
                <div class="social-input-containers ">
                  {" "}
                  
                  <Dropdown
                    className="dropdown"
                    options={options} 
                    onChange={this.onSelectChange} 
                    value={howDidYouHear}
                    placeholder="How did you hear about us" 
                    arrowClosed={<span className="arrow-closed" />}
  arrowOpen={<span className="arrow-open" />}/>
                     
                </div>
              <div className="p-2">
                {checkbox.map((item, index) => {
                  return (
                    <>
                      <input
                        type="checkbox"
                        className="m-2"
                        onChange={() => this.handleCheckbox(index)}
                      />
                      <label className="ml-2">{item.name}</label>
                    </>
                  );
                })}

            

                {/* <input type="checkbox" className="m-2" /><label className="ml-2">MATERNITY</label>
                <input type="checkbox" className="m-2" /><label className="ml-2">KIDS</label>
                <input type="checkbox" className="m-2" /><label className="ml-2">OTHERS</label> */}
              </div>
              <button
                onClick={(e) => this.handleValidation(e)}
                class="btnContact"
              >
                Contact Us
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
