export const homedata = {
  title: "LightFlix Productions",
  description:
    "Welcome to LIGHTFLIX PRODUCTIONS SHARATH CHNADRAPPA",
  canonicalurl: "https://www.anandmamani.in/",
  ogimage: "https://bjpapi.anandmamani.in/uploads/banner/speaker.jpg",
};

export const profilepagedata = {
  title: "LightFlix Productions",
  description:
    "Welcome to LIGHTFLIX PRODUCTIONS SHARATH CHNADRAPPA",
  ogimage: "https://bjpapi.anandmamani.in/uploads/banner/speaker.jpg",
};

export const newsdata = {
  title: "LightFlix Productions",
  description:
    "Welcome to LIGHTFLIX PRODUCTIONS SHARATH CHNADRAPPA",
  ogimage: "https://bjpapi.anandmamani.in/uploads/banner/speaker.jpg",
};

export const gallerypagedata = {
  title: "LightFlix Productions",
  description:
    "Welcome to LIGHTFLIX PRODUCTIONS SHARATH CHNADRAPPA",
  canonicalurl: "https://www.anandmamani.in/",
  ogimage: "https://bjpapi.anandmamani.in/uploads/banner/speaker.jpg",
};

export const contactusdata = {
  title: "LightFlix Productions",
  description:
    "Welcome to LIGHTFLIX PRODUCTIONS SHARATH CHNADRAPPA",
  canonicalurl: "https://www.anandmamani.in/",
  ogimage: "https://bjpapi.anandmamani.in/uploads/banner/speaker.jpg",
};

export const aboutbjpdata = {
  title: "LightFlix Productions",
  description:
    "Welcome to LIGHTFLIX PRODUCTIONS SHARATH CHNADRAPPA",
  canonicalurl: "https://www.anandmamani.in/",
  ogimage: "https://bjpapi.anandmamani.in/uploads/banner/speaker.jpg",
};

export const aboutsavdattidata = {
  title: "LightFlix Productions",
  description:
    "Welcome to LIGHTFLIX PRODUCTIONS SHARATH CHNADRAPPA",
};
