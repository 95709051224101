import React from "react";

export default function TermsAndCondition() {
  return (
    <div>
      <p>
        Privacy Policy for www.lightflixproductions.com Last Updated: 14 jan 2023 Thank
        you for visiting www.lightflixproductions.com ("we," "us," or "our"). This
        Privacy Policy outlines how we collect, use, and safeguard the personal
        information you provide while using our website. 1. Information We
        Collect 1.1 Personal Information: We may collect personal information
        such as your name, email address, and phone number when you voluntarily
        submit it through our contact forms or other website features. 1.2
        Non-Personal Information: We also collect non-personal information, such
        as browser type, operating system, and IP address, to enhance the user
        experience and improve our services. 2. How We Use Your Information 2.1
        Personal Information: We use your personal information to respond to
        your inquiries, provide the services you request, and communicate with
        you about our offerings. 2.2 Non-Personal Information: Non-personal
        information is used for website analytics, helping us understand how
        users interact with our website and improve its functionality. 3.
        Cookies and Tracking Technologies We use cookies and similar tracking
        technologies to enhance your experience on our website. You can manage
        cookie preferences through your browser settings. 4. Third-Party Links
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or content of these external
        sites. Please review their privacy policies before providing any
        personal information. 5. Data Security We implement reasonable security
        measures to protect your personal information. However, no method of
        transmission over the internet or electronic storage is completely
        secure. Therefore, we cannot guarantee absolute security. 6. Children's
        Privacy Our services are not directed to individuals under the age of
        13. If you are a parent or guardian and believe that your child has
        provided us with personal information, please contact us, and we will
        take appropriate action. 7. Changes to This Privacy Policy We reserve
        the right to update this Privacy Policy at any time. Any changes will be
        effective immediately upon posting the updated policy on our website. 8.
        Contact Information If you have any questions about this Privacy Policy,
        please contact us at lightflixproductions.gmail.com. By using our website, you
        consent to the terms outlined in this Privacy Policy.
      </p>
    </div>
  );
}
