export const imageOrientation = (src) => {
    var orientation,
      img = new Image();
    img.src = src;

    if (img.naturalWidth > img.naturalHeight) {
      orientation = "landscape";
    } else if (img.naturalWidth < img.naturalHeight) {
      orientation = "portrait";
    } else {
      orientation = "portrait";
    }

    return orientation;
  };


  export const checkFileSize = (fileSize) => {
    return fileSize < 1048576
  }