import React, { useState } from 'react';
import { withRouter } from "react-router-dom";

const Login = (props) => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = () => {
        if (username === '') {
            alert("Username is empty");
        } else if (password === '') {
            alert("Password is empty");
        } else {
            if (username === "admin" && password === "admin@1234") {
                localStorage.setItem("userType", "admin");
                props.history.push(`/admin/gallery`);
            } else {
                alert("invalid username and password");
            }
           
        }
    }

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-light">Login</h4>
              <form>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label text-light">Username</label>
                  <input type="text" className="form-control" id="username" onChange={(e) => setUserName(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label text-light">Password</label>
                  <input type="password" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} />
                </div>
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Login);
