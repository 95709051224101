// Gallery.js
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { get } from "../../../utils/apiMethods";
import {
  ADD_GALLERY_URL,
  DELETE_GALLERY_URL,
  GET_GALLERY_URL,
  MAIN_URL,
} from "../../../utils/apiConstants";
import DataNotFound from "../../NotFound/DataNotFound";
import { checkFileSize } from "../../../utils/common";
import Loader from "../../../components/Loader/Loader";

const GalleryAdmin = () => {
  const [images, setImages] = useState([]);
  const [thumbnailimg, setthumbnailImg] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [quotes, setQuotes] = useState("");
  const [isModelOn, setIsModelOn] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [galleryData, setGalleryData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const setToIntialState = () => {
    setImages([]);
    setthumbnailImg({});
    setTitle("");
    setDescription("");
    setQuotes("");
    setSelectedFile("");
    setSelectedOption("");
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const callGetApi = async () => {
    const result = await get({ url: GET_GALLERY_URL });
    if (result?.data?.length) {
      setGalleryData(result.data);
    }
  };

  useEffect(() => {
    callGetApi();
  }, []);

  const handleImageUpload = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages([...images, ...selectedImages]);
  };

  const thumbnamilImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    if (checkFileSize(selectedImage.size)) {
      // Now you can proceed to upload the selected image.
      setthumbnailImg(selectedImage);
    } else {
      alert("Image size must be less than 1 mb");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleModel = () => {
    setIsModelOn(!isModelOn);
  };

  const handleDelete = async (id) => {
    const result = await get({ url: `${DELETE_GALLERY_URL}/${id}` });
    if (result.status) {
      window.location.reload();
    }
  };

  const tableComponent = () => {
    return (
      <>
        <div className="d-flex justify-content-end">
          <Button
            className="m-4 "
            variant="primary"
            onClick={() => handleModel()}
          >
            Add Gallery
          </Button>
        </div>
        {galleryData.length ? (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Quotes</th>
                  <th>Thumbnail Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {galleryData?.length ? (
                  galleryData.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{item.quotes}</td>
                        <td>
                          <Image
                            src={`${MAIN_URL}/${item.thumbnailImgUrl.path}`}
                            thumbnail
                          />
                        </td>
                        <td>
                          <Row>
                            {/* <Col md="2">
                    <Button>Edit</Button>
                  </Col> */}
                            <Col md="2">
                              <Button onClick={() => handleDelete(item._id)}>
                                Delete
                              </Button>
                            </Col>
                            {/* <Col>
                    <Button>Edit</Button>
                  </Col> */}
                          </Row>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div>No Data</div>
                )}
              </tbody>
            </Table>
          </>
        ) : (
          <DataNotFound />
        )}
      </>
    );
  };

  const handleSubmit = async () => {
    let validation = false;
    let formData = new FormData();
    if (images.length) {
      images.map((file) => {
        if (checkFileSize(file.size)) {
          formData.append("images", file);
        } else {
          validation = true;
          return alert("Please check image size and try again");
        }
      });
    } else {
      validation = true;
      alert("Please select images");
    }
    if (thumbnailimg) {
      formData.append("thumbnail_image", thumbnailimg);
    } else {
      validation = true;
      alert("Please select thumbnail image");
    }

    // formData.append("audio", selectedFile);
    if (title !== "") {
      formData.append("title", title);
    } else {
      alert("Please add title");
      validation = true;
    }

    if (description !== "") {
      formData.append("description", description);
    } else {
      validation = true;
      alert("Please add description");
    }

    if (quotes !== "") {
      formData.append("quotes", quotes);
    } else {
      validation = true;
      alert("Please add quotes");
    }

    if (selectedOption !== "") {
      formData.append("type", selectedOption);
    } else {
      validation = true;
      alert("Please add type of gallery");
    }

    if (validation) {
      alert("Validation failed Please check and try");
    } else {
      setIsLoading(true);
      await fetch(ADD_GALLERY_URL, {
        method: "POST",
        headers: {},
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status) {
            setIsLoading(false);
            setToIntialState();
            callGetApi();
            handleModel();
          } else {
            setIsLoading(false);
            alert("Issue please try again later")
          }
        });
    }
  };

  const handleImageDelete = (index) => {
    const imagesData = [...images];
    
    imagesData.splice(index, 1);
    console.log("imageData", imagesData);
    setImages(imagesData);
  };

  const addModelGallery = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModelOn}
      >
        <Modal.Body>
          <div className="container mt-5">
            <Row>
              <Col xl="6" md="6">
                <Form.Control
                  placeholder="Title"
                  aria-label="Title"
                  onChange={(e) => setTitle(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col xl="6" md="6">
                <Form.Control
                  placeholder="Description"
                  aria-label="description"
                  onChange={(e) => setDescription(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Row className="py-2">
              <Col xl="6" md="6">
                <Form.Control
                  placeholder="quotes"
                  aria-label="quotes"
                  onChange={(e) => setQuotes(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col xl="6" md="6">
                <h5>thumbnail image</h5>
                <input type="file" onChange={thumbnamilImageUpload} />
              </Col>
            </Row>
            <h1>Image Gallery</h1>
            <input type="file" multiple onChange={handleImageUpload} />

            <div class="m-2">
              <label>
                <input
                  type="radio"
                  value="wedding"
                  checked={selectedOption === "wedding"}
                  onChange={handleOptionChange}
                />
                Wedding
              </label>
              <label>
                <input
                  type="radio"
                  value="maternity"
                  checked={selectedOption === "maternity"}
                  onChange={handleOptionChange}
                />
                maternity
              </label>
              <label>
                <input
                  type="radio"
                  value="kids"
                  checked={selectedOption === "kids"}
                  onChange={handleOptionChange}
                />
                kids
              </label>
            </div>
            {images.length ? (
              <table className="table mt-3">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Size</th>
                  </tr>
                </thead>
                <tbody>
                  {images.map((image, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Image ${index + 1}`}
                          style={{ maxWidth: "100px" }}
                        />
                      </td>
                      <td>
                        {checkFileSize(image.size)
                          ? "less than 1 mb"
                          : "More than 1 mb"}
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            handleImageDelete(index);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleModel()}>
            Close
          </Button>
          <Button variant="success" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div>{tableComponent()}</div>
          <div>{addModelGallery()}</div>
        </>
      )}
     
    </>
  );
};

export default GalleryAdmin;
