import React from 'react'
import Aboutinfo from '../../components/Aboutinfo/Aboutinfo'

export default function Aboutus() {
  return (
    <div>
        <Aboutinfo />
    </div>
  )
}
