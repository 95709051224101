import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  ADD_CUSTOMER_URL,
  DELETE_CUSTOMER_URL,
  GET_CUSTOMER_URL,
  MAIN_URL,
} from "../../../utils/apiConstants";
import { get } from "../../../utils/apiMethods";
import DataNotFound from "../../NotFound/DataNotFound";
import { checkFileSize } from "../../../utils/common";

export default function HappyCustomer() {
  const [isModelOn, setIsModelOn] = useState(false);
  const [name, setName] = useState("");
  const [message , setMessage] = useState("");
  const [thumbnailimg, setthumbnailImg] = useState();
  const [bannerData, setBannerData] = useState([]);

  const callGetApi = async () => {
    const result = await get({ url: GET_CUSTOMER_URL });
    if (result?.data?.length) {
      setBannerData(result.data);
    }
  };

  useEffect(() => {
    callGetApi();
  }, []);

  const handleModel = () => {
    setIsModelOn(!isModelOn);
  };

  const thumbnamilImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    if (checkFileSize(selectedImage.size)) {
      // Now you can proceed to upload the selected image.
      setthumbnailImg(selectedImage);
    } else {
      alert("Image size must be less than 1 mb");
    }
    // Now you can proceed to upload the selected image.
    // setthumbnailImg(selectedImage);
  };

  const handleSubmit = async () => {
    let validation = false;
    let formData = new FormData();
    if (thumbnailimg) {
      formData.append("images", thumbnailimg);
    } else {
      validation = true;
      alert("Please add images");
    }
    if (name !== "") {
      formData.append("name", name);
    } else {
      validation = true;
      alert("Enter name")
    }
   
    if (message !== "") {
      formData.append("message", message);
    } else {
      validation = true;
      alert("Enter message")
    }
  
    if (validation) {
      alert ("Please check the validation")
    } else {
      await fetch(ADD_CUSTOMER_URL, {
        method: "POST",
        headers: {},
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.status) {
            callGetApi();
            handleModel();
          }
        });
    }
  
  };

  const handleDelete = async (id) => {
    const result = await get({ url: `${DELETE_CUSTOMER_URL}/${id}` });
    if (result.status) {
      window.location.reload();
    }
  };

  const tableComponent = () => {
    return (
      <>
        <div className="d-flex justify-content-end">
          <Button
            className="m-4 "
            variant="primary"
            onClick={() => handleModel()}
          >
            Add Customer
          </Button>
        </div>
        {bannerData.length ? (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Message</th>
                  <th>customer Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {bannerData.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.message}</td>
                      <td>
                        <Image
                          src={`${MAIN_URL}/${item.imageUrl[0].path}`}
                          thumbnail
                        />
                      </td>
                      <td>
                        <Row>
                          {/* <Col md="2">
                      <Button>Edit</Button>
                    </Col> */}
                          <Col md="2">
                            <Button onClick={() => handleDelete(item._id)}>
                              Delete
                            </Button>
                          </Col>
                          {/* <Col>
                      <Button>Edit</Button>
                    </Col> */}
                        </Row>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <DataNotFound />
        )}
      </>
    );
  };

  const addModelGallery = () => {
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isModelOn}
      >
        <Modal.Body>
          <div className="container mt-5">
            <Row>
              <Col xl="6" md="6">
                <Form.Control
                  placeholder="Name"
                  aria-label="Name"
                  onChange={(e) => setName(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Row>
              <Col xl="6" md="6">
                <Form.Control
                  placeholder="Message"
                  aria-label="message"
                  onChange={(e) => setMessage(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Row className="py-2">
              <Col xl="6" md="6">
                <h5>Customer image</h5>
                <input type="file" onChange={thumbnamilImageUpload} />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleModel()}>
            Close
          </Button>
          <Button variant="success" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {tableComponent()}
      {addModelGallery()}
    </>
  );
}
