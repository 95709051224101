import React from "react";
import Accordion from "react-bootstrap/Accordion";

const data = [
  {
    question: "What is your approach towards making a wedding film?",
    answer:
      "Our approach to wedding films blends modernity with storytelling. We prioritize meaningful visuals and emotions, believing in the power of cinematic storytelling to capture weddings uniquely and beautifully.",
  },
  {
    question:
      "Our wedding won't be a grand affair, can we still hire your services?",
    answer:
      "Of course! Even if your wedding is not extravagant, we're still available and excited to work with you. Your special day is important to us, no matter the scale.",
  },
  {
    question: "Do you travel ?",
    answer: "Yes we do travel all across the world.",
  },
  {
    question:
      "How can we ensure our photographer captures all the important moments?",
    answer:
      "Create a detailed shot list and communicate your timeline and priorities with your photographer. This will help ensure nothing important is missed.",
  },
  {
    question: "What are the deliverables ?",
    answer:
      "The deliverables varies based on the package you choose, ideally would be A1 minute cinematic trailer and a 5-10 minute cinematic highlight film are our main deliverables. Along with that we give separate full video of whole event, 500 edited photographs and a photobook. ",
  },
  {
    question: "What's the difference between wedding photography packages?",
    answer:
      "Packages may include different hours of coverage, the number of photographers, prints or albums, engagement sessions, and more. Choose one that suits your needs.",
  },
  {
    question: "What's the best way to prepare for wedding photography?",
    answer:
      "Ensure good communication with your photographer, be relaxed, and enjoy the moment. Trust their expertise to capture your special day.",
  },
  {
    question: "When can we expect the delivery ?",
    answer:
      "Editing a film is a creative process and we take it very very seriously. Each film goes through a series of iterations till we reach a point where we can proudly say 'this is our best work till date'. It usually takes us 3-4 months to deliver your complete film based on our work schedule.",
  },
  {
    question: "Are you ok if we hire additional videographers?",
    answer:
      "No we are not. We try to keep our compositions clean by avoiding distractions and people carrying cameras are a distraction (including our own team members) . We work very closely within our team to ensure we do not obstruct each other's frames and for this very reason we work in exclusivity. ",
  },
  {
    question: "How big is your team ? ",
    answer:
      "The size of the team is decided based on individual requirements. A team of four is good enough to capture a wedding in its entirety but we can also get a team of 10 if needed.",
  },
  {
    question: "My wedding is next year, is it too early to enquire?",
    answer:
      "Since we take up very limited weddings each year we are usually booked 8-9 months in advance.",
  },
  {
    question: "What are your payment terms ?",
    answer:
      "We take 50% of the total booking amount at the time of booking , the remaining balance is due on the first day of coverage.",
  },
];

export default function Faq() {
  return (
    <div>
      <Accordion>
        {data.map((item, index) => {
          return (
            <Accordion.Item eventKey={index}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>
                {item.answer}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}
