import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { imageOrientation } from "../../../utils/common";
import  "./SubGallery.css"
import { get } from "../../../utils/apiMethods";
import { GET_GALLERY_URL, MAIN_URL } from "../../../utils/apiConstants";

export default function SubGallery(props) {
  const routeParams = useParams();
  console.log("routeParams", routeParams);
  const [pageDetails, setPageDetails] = useState({});
  window.scrollTo(0,0);
  useEffect(() => {
    callGetApi();
  }, []);

  const callGetApi = async () => {
    const result = await get({ url: GET_GALLERY_URL });
    if (result?.data?.length) {
      console.log("Coming", result?.data);
      const galleryData = result.data.filter((item) => item._id === routeParams.id);
      console.log("galleryData", galleryData);
      setPageDetails(galleryData[0]);
    }
  };
  console.log("pageDetails", pageDetails);
  return (
    <Container>
        <h1 className="photo-title">{pageDetails.title}</h1>
      <Image src={`${MAIN_URL}/${pageDetails?.thumbnailImgUrl?.path}`} thumbnail style={{    "width":"-webkit-fill-available",
    "height": "-webkit-fill-available"}}/>
        <p className="para-data">{pageDetails.quotes}</p>
        <p className="para-data">{pageDetails.description}</p>
      
      <Row>
        {pageDetails?.galleryImgUrls?.length ? (
          pageDetails.galleryImgUrls.map((item) => {
            const imageValue = imageOrientation(item) === "portrait" ? 6 : 12;
            return (
              <Col xs={6} md={imageValue}>
                <Image src={`${MAIN_URL}/${item?.path}`} thumbnail />
              </Col>
            );
          })
        ) : (
          <div>Data not found</div>
        )}
      </Row>
    </Container>
  );
}
