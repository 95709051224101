import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Contact from "../pages/Contact/Contact";

import NotFound from "../pages/NotFound/NotFound";

import Loader from "../components/Loader/Loader";
import GalleryNew from "../pages/GalleryNew/GalleryNew";
import SubGallery from "../pages/GalleryNew/SubGallery/SubGallery";
import Login from "../pages/Admin/Login/Login";
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import GalleryAdmin from "../pages/Admin/GalleryAdmin/GalleryAdmin";
import BannerAdmin from "../pages/Admin/BannerAdmin/BannerAdmin";
import ContactUsAdmin from "../pages/Admin/ContactUsAdmin/ContactUsAdmin";
import VideoAdmin from "../pages/Admin/VideoAdmin/VideoAdmin";
import Aboutus from "../pages/Aboutus/Aboutus";
import Faq from "../pages/Faq/Faq";
import HappyCustomer from "../pages/Admin/HappyCustomerAdmin/HappyCustomer";
import TermsAndCondition from "../pages/TermsAndCondition/TermsAndCondition";

const home = lazy(() => import("../pages/Home/Home"));
export default class RouterMain extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let url = window.location.href;
    if ((url && decodeURI(url).indexOf("?")) > -1) {
      const queryStartIndex = decodeURI(url).indexOf("?") + 1;
      let queryParams = url.split('&');
      queryParams = queryParams.filter(item => !!item);
      url = queryParams.join('&');
      if(url && url.indexOf("fbclid") > -1) {
        // debugger;
        window.location.assign("/");
      }
      
    }
  }

  render() {
    return (
      <div>
        <Router>
          <Header />
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path="/" component={home} />
              <Route exact path="/gallery/:type"  component={GalleryNew}/>
              <Route exact path="/gallery/:type/:title/:id"  component={SubGallery}/>

              <Route exact path="/admin/login" component={Login} />
              <Route exact path="/admin/dashboard" component={Dashboard} />
              <Route exact path="/admin/gallery" component={GalleryAdmin} />
              <Route exact path="/admin/banner" component={BannerAdmin} />
              <Route exact path="/admin/happyCustomer" component={HappyCustomer} />
              <Route exact path="/admin/contactus" component={ContactUsAdmin} />
              <Route exact path="/admin/video" component={VideoAdmin} />
              <Route exact path="/aboutus" component={Aboutus} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/terms-and-conditions" component={TermsAndCondition} />
              
              <Route exact component={NotFound} />
            </Switch>
          </Suspense>
          <Footer />
        </Router>
      </div>
    );
  }
}
